<template>
	<b-modal
		ref="modal-Create"
		@hidden="$emit('close')"
		:title="`${info.provider_id ? 'Actualizar proveedor' : 'Crear proveedor'}`"
		cancel-title="Cancelar"
		scrollable
		size="lg"
		no-close-on-backdrop
		no-close-on-esc
	>
		<div>
			<ValidationObserver ref="formProvider">
				<div class="containerProviders">
					<div>
						<div>
							<b-form-group label="Logo">
								<b-avatar
									v-model="form.logotype"
									class="clickable text-dark w-100"
									style="border: 2px dashed var(--dark)"
									ref="previewEl"
									icon="image"
									square
									rounded="lg"
									variant="light"
									:src="form.logotype"
									:size="heightImage"
									@click="$refs.fileInput.click()"
								/>
								<div class="d-flex justify-content-end" style="margin-top: 5px">
									<span>
										<feather-icon
											size="22"
											icon="XOctagonIcon"
											class="cursor-pointer text-danger mr-50"
											@click="deletePhoto()"
										/>
										<feather-icon
											size="22"
											icon="UploadIcon"
											class="cursor-pointer text-primary"
											@click="$refs.fileInput.click()"
										/>
									</span>
								</div>
								<input
									ref="fileInput"
									type="file"
									accept=".jpeg,.jpg,.png,.heif,.heic"
									@input="pickFile"
									class="d-none"
								/>
							</b-form-group>
						</div>
					</div>
					<div>
						<div>
							<ValidationProvider v-slot="{ errors }" rules="required|min:11|max:11">
								<b-form-group :state="errors[0] ? false : null">
									<label for="name">Numero de ruc</label>
									<b-form-input
										id="name"
										:state="errors[0] ? false : null"
										type="number"
										placeholder="Numero de ruc..."
										v-model="form.ruc_number"
									/>
									<small v-if="errors.length > 0" class="text-danger">
										{{ errors[0] }}
									</small>
								</b-form-group>
							</ValidationProvider>
						</div>

						<div>
							<!-- <ValidationProvider v-slot="{ errors }" rules="required|max:10">
								|
								<pre>{{ errors }}</pre>
								<b-form-group :state="errors[0] ? false : null">
									<label for="name">Razon social</label>
									<b-form-input
										v-model="form.company_name"
										id="name"
										:state="errors[0] ? false : null"
										type="text"
										placeholder="Razon social"
										v-validate="'digits:3'"
									/>
								</b-form-group>
							</ValidationProvider> -->

							<ValidationProvider
								v-slot="{ errors }"
								rules="required|max:30"
								:persist="true"
								name="description"
							>
								<b-form-group label="Razon social">
									<b-form-input
										v-model="form.company_name"
										type="text"
										class="form-control"
										:class="errors[0]"
										:state="errors.length > 0 ? false : null"
										placeholder="Razon social..."
									/>
									<small v-if="errors.length > 0" class="text-danger">
										{{ errors[0] }}
									</small>
								</b-form-group>
							</ValidationProvider>
						</div>

						<!-- <div>
							<ValidationProvider v-slot="{ errors }" rules="required">
								<b-form-group :state="errors[0] ? false : null">
									<label for="name">Pais</label>
									<b-form-input
										id="name"
										:state="errors[0] ? false : null"
										type="text"
										placeholder="Ingrese el pais"
										v-model="form.country"
									/>
								</b-form-group>
							</ValidationProvider>
						</div>

						<div>
							<ValidationProvider v-slot="{ errors }" rules="required">
								<b-form-group :state="errors[0] ? false : null">
									<label for="name">Ciudad</label>
									<b-form-input
										id="name"
										:state="errors[0] ? false : null"
										type="text"
										placeholder="Ingrese la ciudad"
										v-model="form.city"
									/>
								</b-form-group>
							</ValidationProvider>
						</div> -->

						<div>
							<ValidationProvider v-slot="{ errors }" rules="required|max:100">
								<b-form-group :state="errors[0] ? false : null">
									<label for="name">Direccion</label>
									<b-form-input
										id="name"
										:state="errors[0] ? false : null"
										type="text"
										placeholder="Direccion..."
										v-model="form.address"
									/>
									<small v-if="errors.length > 0" class="text-danger">
										{{ errors[0] }}
									</small>
								</b-form-group>
							</ValidationProvider>
						</div>

						<div>
							<ValidationProvider>
								<b-form-group>
									<label for="name">Sitio web</label>
									<b-form-input
										id="name"
										type="text"
										placeholder="www.example.com"
										v-model="form.website"
									/>
								</b-form-group>
							</ValidationProvider>
						</div>
					</div>
				</div>
				<div class="d-flex justify-content-between align-items-center" style="margin-bottom: 5px">
					<h5 class="mb-0">Contactos:</h5>
					<b-button variant="success" class="btn-icon btn-sm" @click="addContact">
						<feather-icon icon="PlusIcon" />
					</b-button>
				</div>
				<div class="border-top-primary border-3">
					<div class="custom-scroll mt-1">
						<div>
							<div class="d-flex containerLabel">
								<span class="custom-code labelCode">Cargo</span>
								<span class="custom-code labelCode">Nombre</span>
								<span class="custom-code labelCode">Telefono</span>
								<span class="custom-code labelCode">Email</span>
							</div>
						</div>

						<div
							v-if="getContact.length == 0"
							class="d-flex justify-content-center align-items-center"
							style="min-height: 150px; max-height: 300px"
						>
							<h4>No hay contactos agregados</h4>
						</div>
						<div>
							<template v-for="(contact, key) in getContact">
								<div class="d-flex container-input">
									<div class="d-flex container-input">
										<ValidationProvider v-slot="{ errors }" rules="required">
											<b-form-group class="custom-code" :state="errors[0] ? false : null">
												<b-form-input
													type="text"
													placeholder="Cargo..."
													:state="errors[0] ? false : null"
													v-model="contact.job_position"
													class="w-100"
												/>
												<small v-if="errors.length > 0" class="text-danger">
													{{ errors[0] }}
												</small>
											</b-form-group>
										</ValidationProvider>
										<ValidationProvider v-slot="{ errors }" rules="required">
											<b-form-group class="custom-code" :state="errors[0] ? false : null">
												<b-form-input
													type="text"
													placeholder="Nombre..."
													:state="errors[0] ? false : null"
													v-model="contact.name"
													class="w-100"
												/>
												<small v-if="errors.length > 0" class="text-danger">
													{{ errors[0] }}
												</small>
											</b-form-group>
										</ValidationProvider>

										<ValidationProvider v-slot="{ errors }" rules="required|min:5|max:15">
											<b-form-group class="custom-code" :state="errors[0] ? false : null">
												<b-form-input
													type="number"
													:state="errors[0] ? false : null"
													placeholder="Telefono..."
													v-model="contact.phone"
													class="w-100"
												/>
												<small v-if="errors.length > 0" class="text-danger">
													{{ errors[0] }}
												</small>
											</b-form-group>
										</ValidationProvider>

										<ValidationProvider v-slot="{ errors }">
											<b-form-group class="custom-code" :state="errors[0] ? false : null">
												<b-form-input
													type="text"
													:state="errors[0] ? false : null"
													placeholder="Email..."
													v-model="contact.email"
													class="w-100"
												/>
											</b-form-group>
										</ValidationProvider>
										<div>
											<b-button
												variant="danger"
												class="btn-icon btn-sm"
												style="margin-top: 3px"
												@click="removeContact(key)"
											>
												<feather-icon icon="TrashIcon" />
											</b-button>
										</div>
									</div>
								</div>
							</template>
						</div>
					</div>
				</div>
			</ValidationObserver>
		</div>
		<template #modal-footer>
			<b-button variant="danger" @click="$emit('close')">Cancelar</b-button>
			<b-button v-if="info.provider_id" variant="primary" @click="updateProveedor">Actualizar</b-button>
			<b-button v-else variant="primary" @click="registerProveedor">Guardar</b-button>
		</template>
	</b-modal>
</template>
<script>
import modalMixin from "@/mixins/modal.js"
import Ripple from "vue-ripple-directive"
import ProviderService from "@/views/brain/administrative/views/providers/services/providers.service.ts"
import { ValidationProvider } from "vee-validate"

export default {
	components: {
		ValidationProvider,
	},
	props: {
		info: {
			required: true,
		},
	},
	directives: { Ripple },
	mixins: [modalMixin],

	data() {
		return {
			isAdd: false,
			form: {
				provider_id: null,
				company_name: null,
				ruc_number: null,
				country: null,
				city: null,
				address: null,
				website: null,
				logotype: null,
				file: null,
				provider_contacts: [],
			},
		}
	},
	async mounted() {
		this.toggleModal("modal-Create")
	},
	created() {
		if (this.info.provider_id != null) {
			this.form.provider_id = this.info.provider_id
			this.form.company_name = this.info.company_name
			this.form.ruc_number = this.info.ruc_number
			this.form.country = this.info.country
			this.form.city = this.info.city
			this.form.address = this.info.address

			if (this.info.website) {
				this.form.website = this.info.website ? this.info.website : ""
			}
			this.form.logotype = this.info.logotype
			if (this.form.provider_contacts) {
				this.form.provider_contacts = this.info?.provider_contacts
			}
		}
	},

	computed: {
		heightImage() {
			return ["md", "lg", "xl"].includes(this.currentBreakPoint) ? "18rem" : "15rem"
		},

		getContact() {
			return this.form.provider_contacts
		},
	},
	methods: {
		addContact() {
			this.isAdd = true
			this.form.provider_contacts.push({
				job_position: null,
				name: null,
				phone: null,
				email: null,
			})
		},

		editContact(index) {
			// Cambiar el estado de editing del contacto seleccionado
			this.form.contact[index].editing = !this.form.contact[index].editing
		},
		removeContact(index) {
			this.form.provider_contacts.splice(index, 1)
		},

		deletePhoto() {
			this.form.logotype = null
			this.form.file = null
		},

		async registerProveedor() {
			const validate = await this.$refs.formProvider.validate()
			if (!validate) return

			const confirm = await this.showConfirmSwal({
				text: "Registrar Proveedor",
			})
			if (!confirm.isConfirmed) return

			try {
				this.isPreloading()
				const formData = new FormData()
				formData.append("company_name", this.form.company_name)
				formData.append("ruc_number", this.form.ruc_number)
				formData.append("country", this.form.country)
				formData.append("city", this.form.city)
				formData.append("address", this.form.address)
				formData.append("website", this.form.website)
				formData.append("logotype", this.form.file)
				formData.append("contacts", JSON.stringify(this.form.provider_contacts))
				const registered = await ProviderService.addProvider(formData)
				this.showSuccessToast(registered.data.message)
				this.$emit("registered")
			} catch (error) {
				console.log(error)
			} finally {
				this.isPreloading(false)
			}
		},

		async updateProveedor() {
			const validate = await this.$refs.formProvider.validate()
			if (!validate) return

			const confirm = await this.showConfirmSwal({
				text: "Actualizar Proveedor",
			})
			if (!confirm.isConfirmed) return

			try {
				this.isPreloading()
				const formData = new FormData()
				formData.append("company_name", this.form.company_name)
				formData.append("ruc_number", this.form.ruc_number)
				formData.append("country", this.form.country)
				formData.append("city", this.form.city)
				formData.append("address", this.form.address)
				formData.append("website", this.form.website)
				formData.append("logotype", this.form.file)
				formData.append(
					"contacts",
					JSON.stringify(
						this.form.provider_contacts.map((pro) => {
							const data = {
								name: pro.name,
								job_position: pro.job_position,
								phone: pro.phone,
								email: pro.email,
							}
							return data
						})
					)
				)

				const registered = await ProviderService.updateProvider(this.form.provider_id, formData)
				this.showSuccessToast(registered.data.message)
				this.$emit("registered")
			} catch (error) {
				console.log(error)
			} finally {
				this.isPreloading(false)
			}
		},

		pickFile() {
			let input = this.$refs.fileInput
			let file = input.files

			const typeOfFile = file[0] && file[0].name.split(".")[file[0].name.split(".").length - 1]
			if (!["jpeg", "jpg", "png"].includes(typeOfFile)) {
				this.showToast(
					"warning",
					"top-right",
					"Formato de archivo no valido",
					"CheckIcon",
					"Los formatos aceptados son .jpeg, .jpg y .png ."
				)
				return
			}
			if (typeOfFile === "heic" || typeOfFile === "heif") {
				this.isPreloading()
				this.showToast(
					"warning",
					"top-right",
					"Cargando imagen, espere un momento",
					"CheckIcon",
					"El formato HEIF o HEIC no es soportado, por ello lo puede tardar un momento."
				)
				heic2any({ blob: file[0], toType: "image/jpg", quality: 1 }).then((newImage) => {
					const url = URL.createObjectURL(newImage)
					let newFile = new File([newImage], "heic" + ".jpg", {
						type: "image/jpeg",
						lastModified: new Date().getTime(),
					})
					this.form.file = newFile
					this.form.logotype = url
					this.isPreloading(false)
				})
			} else {
				this.form.file = file[0]
				if (file && file[0]) {
					let reader = new FileReader()
					reader.onload = (e) => {
						this.form.logotype = e.target.result
					}
					reader.readAsDataURL(file[0])
					this.form.file = file[0]
				}
			}
		},
	},
}
</script>
<style>
.containerProviders {
	display: grid;
	grid-template-columns: 1fr 1fr;
	width: 100%;
	gap: 2rem;
}

@media screen and (max-width: 768px) {
	.containerProviders {
		grid-template-columns: 1fr; /* Cambia a una sola columna en dispositivos con un ancho máximo de 768px */
	}
}

.labelCode {
	text-align: start !important;
}

.containerLabel {
	display: grid !important;
	grid-template-columns: 1fr 1fr 1fr 1fr;
	place-items: center;
	gap: 1rem;
	width: 94%;
}

@media screen and (max-width: 768px) {
	.containerLabel {
		gap: 0rem;
		width: auto;
	}
}

.container-input {
	gap: 1rem;
}

@media screen and (max-width: 768px) {
	.container-input {
		gap: 0rem;
	}
}

.custom-code {
	width: 100%;
	min-width: 120px;
}
@media screen and (max-width: 768px) {
	.custom-code {
		width: 100px;
		min-width: 100px;
		margin-left: 5px;
		margin-right: 8px;
	}
}

/* .containerInput {
	display: grid;
	grid-template-columns: 1fr 1fr 1fr 1fr;
	gap: 1rem;
} */

/* .container-contact {
	display: grid;
	grid-template-columns: 1fr 1fr 1fr 1fr;

} */

@media screen and (max-width: 768px) {
	.custom-scroll {
		overflow-y: scroll;
		min-height: auto;
		max-height: 300px;
	}
}
</style>
