export default [
	// {
	// 	key: "provider_id",
	// 	label: "#",
	// 	visible: true,
	// 	thClass: "text-center",
	// },

	{
		key: "logotype",
		label: "Logo",
		visible: true,
		thClass: "text-center",
	},
	{
		key: "company_name",
		label: "Razon social",
		visible: true,
		thClass: "text-center",
	},

	{
		key: "ruc_number",
		label: "Ruc",
		visible: true,
		thClass: "text-center",
	},

	// {
	// 	key: "country",
	// 	label: "Pais",
	// 	visible: true,
	// 	thClass: "text-center",
	// },

	// {
	// 	key: "city",
	// 	label: "Ciudad",
	// 	visible: true,
	// 	thClass: "text-center",
	// },
	{
		key: "address",
		label: "Direccion",
		visible: true,
		thClass: "text-center",
	},

	{
		key: "website",
		label: "Sitio web",
		visible: true,
		thClass: "text-center",
	},

	{
		key: "created_by",
		label: "Creado por",
		visible: true,
		thClass: "text-center",
	},
	// {
	// 	key: "created_at",
	// 	label: "Creado por",
	// 	visible: true,
	// 	thClass: "text-center",
	// },
	{
		key: "actions",
		label: "Acciones",
		thClass: "text-center",
		visible: true,
	},
]
