<template>
	<div>
		<div class="specimen">
			<img
				v-if="is_specimen"
				onerror="this.src='/rooster/no_image.jpg'"
				@click.self="clickImageSpecimen(specimen)"
				:class="{ 'img-specimen': !isDetail, 'img-specimen-detail': isDetail }"
				:src="specimen.url ? specimen.url : '/rooster/no_image.jpg'"
				:alt="specimen.id"
			/>
		</div>

		<SlidersImageVideo
			:url="clickImage.url"
			:type="clickImage.type"
			:text="clickImage.text"
			:text_url="clickImage.text_url"
			:open="clickImage.open"
			:isSimple="isSimple"
			v-if="clickImage.open"
			@refreshSlider="refresh"
			:idSpecimen="specimen.id"
			:hideText="hideText"
		/>
	</div>
</template>

<script>
import { ref } from "@vue/composition-api"
import SlidersImageVideo from "@/views/amg/header-bookmarks/SlidersImageVideo.vue"

export default {
	name: "SpecimenPlate",
	props: {
		specimen: {
			type: Object,
			required: true,
		},
		is_specimen: {
			type: Boolean,
			default: true,
		},
		isDetail: {
			required: false,
			type: Boolean,
			default: false,
		},
		hideText: {
			type: Boolean,
			default: true,
		},
	},
	components: {
		SlidersImageVideo,
	},
	setup() {
		const newObjectClickImage = () => ref({ url: null, type: "image", text: null, text_url: null, open: false })

		const clickImage = newObjectClickImage()
		const isSimple = ref(true)

		const clickImageSpecimen = (item) => {
			// console.log('ref', item);
			clickImage.value = {
				open: true,
				text_url: `${item?.description}`,
				type: "image",
				text: `${item?.description}`,
				url: item.url ? item.url : "/rooster/no_image.jpg",
			}
			isSimple.value = true
		}

		return {
			clickImage,
			clickImageSpecimen,
			isSimple,
			refresh: () => (clickImage.value = newObjectClickImage()),
		}
	},
}
</script>

<style lang="scss" scoped>
.specimen {
	display: flex;
	align-items: center;
	justify-content: space-between;
	column-gap: 1.5rem;
	// background: red;
	// width: auto;
}

@media screen and (min-width: 700px) {
	.specimen {
		flex-direction: row;
		justify-content: center;
	}
}
.img-specimen {
	width: 50px;
	height: 50px;
	// margin-right: 1rem;
	// border-radius: 8px;
	// box-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1);
	// padding: 0.4rem;
	object-fit: contain;
	box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
	cursor: pointer;
	@media screen and (min-width: 700px) {
		width: 50px;
		height: 50px;
		border-radius: 50%;
		object-fit: contain;
		// background: red;
	}
}

.img-specimen-detail {
	width: 100%;
	height: 148px;
	// margin-right: 1rem;
	// border-radius: 8px;
	// box-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1);
	// padding: 0.4rem;
	object-fit: contain;
	cursor: pointer;
	@media screen and (min-width: 700px) {
		width: 100%;
		height: 148px;
		object-fit: contain;
		// background: red;
	}
}
.info {
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 100px;

	@media screen and (min-width: 700px) {
		align-items: center;
	}
	.alias {
		margin: 0.3rem 0;
		// font-weight: bold;
		color: #6e6b7b !important;
		font-size: 14px;
	}
	.container-text {
		display: flex;
		align-items: center;
		flex-direction: column;
	}
}
.plate {
	margin-top: 0.5rem;
	// background: rgba(0, 0, 0, 0.7);
	// color: white;
	font-weight: bold;
	padding: 0.08rem 0.5rem !important;
	border-radius: 6px;
	width: auto;
	font-size: 12px;
	margin-bottom: 0.5rem !important;
	@media screen and (min-width: 700px) {
		font-size: 14px;
		padding: 0.1rem 0.8rem !important;
		border-radius: 8px;
	}
}
.plate-additional {
	color: var(--primary);
}
</style>
