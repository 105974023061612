<template>
	<div>
		<b-card no-body class="p-1">
			<div class="mb-2">
				<div class="mx-2 mb-2">
					<b-row>
						<b-col
							cols="12"
							sm="6"
							class="d-flex align-items-center justify-content-center justify-content-sm-start pl-0"
						>
							<span class="text-muted">
								Mostrando {{ start_page ?start_page : 0 }} a {{ to_page ?  to_page: 0 }} de {{ total_data }} registros
							</span>
						</b-col>
						<!-- Pagination -->
						<b-col
							cols="12"
							sm="6"
							class="d-flex align-items-center justify-content-center justify-content-sm-end"
						>
							<b-pagination
								v-model="current_page"
								:total-rows="total_data"
								:per-page="perpage"
								first-number
								last-number
								class="mb-0 mt-1 mt-sm-0"
								prev-class="prev-item"
								next-class="next-item"
							>
								<template #prev-text>
									<feather-icon icon="ChevronLeftIcon" size="18" />
								</template>
								<template #next-text>
									<feather-icon icon="ChevronRightIcon" size="18" />
								</template>
							</b-pagination>
						</b-col>
					</b-row>

					<b-row>
						<b-col cols="12" xl="4" class="d-flex align-items-center justify-content-start mb-1 mb-md-0">
							<label>Mostrar</label>
							<v-select
								v-model="perpage"
								:options="perPageOptions"
								:clearable="false"
								class="per-page-selector d-inline-block mx-50"
							/>
							<label class="mr-2">registros</label>
							<feather-icon
								class="cursor-pointer"
								icon="RefreshCcwIcon"
								size="20"
								@click="refreshTable"
							/>
						</b-col>

						<!-- Search -->
						<b-col cols="12" xl="8" class="mt-1">
							<div class="container-btns-page-specimens d-flex justify-content-end">
								<div class="d-flex">
									<b-form-input
										v-model="search_input"
										class="d-inline-block mr-1"
										placeholder="Buscar proveedor..."
										debounce="500"
										@keyup.enter="$refs['table-providers'].refresh()"
									/>
								</div>
							</div>
						</b-col>
					</b-row>
				</div>
			</div>

			<div class="table-responsive">
				<b-table
					:items="myProvider"
					:fields="fields"
					sticky-header="40vh"
					primary-key="id"
					:current-page="current_page"
					:per-page="perpage"
					responsive
					small
					show-empty
					ref="table-providers"
				>
					<template #table-busy>
						<div class="text-center text-primary my-2">
							<b-spinner class="align-middle"></b-spinner>
							<strong>Cargando...</strong>
						</div>
					</template>
					<template #cell(company_name)="data">
						<div class="d-flex d-code justify-content-center align-items-center">
							<span>{{ data.item.company_name }}</span>
						</div>
					</template>

					<template #cell(ruc_number)="data">
						<div class="d-flex d-code justify-content-center align-items-center">
							<span>{{ data.item.ruc_number }}</span>
						</div>
					</template>

					<template #cell(country)="data">
						<div class="d-flex d-code justify-content-center align-items-center">
							<span>{{ data.item.country }}</span>
						</div>
					</template>

					<template #cell(city)="data">
						<div class="d-flex d-code justify-content-center align-items-center">
							<span>{{ data.item.city }}</span>
						</div>
					</template>

					<template #cell(address)="data">
						<div class="d-flex d-code justify-content-center align-items-center">
							<span>{{ data.item.address }}</span>
						</div>
					</template>

					<template #cell(website)="data">
						<div class="d-flex d-code justify-content-center align-items-center">
							<span>{{ data.item.website ===  null ? '---'   : data.item.website }}</span>
						</div>
					</template>

					<template #cell(logotype)="data">
						<div class="d-flex d-code justify-content-center align-items-center">
							<ViewImage :specimen="data.item" />
						</div>
					</template>
					<template #cell(created_by)="data" class="">
						<div class="d-flex d-code justify-content-center flex-column align-items-center">
							<span>{{ data.item.created_by.name }}</span>
							<span>{{ data.item.created_by.date |myGlobalDay  }}</span>
						</div>
					</template>
					<template #cell(actions)="data">
						<div class="d-flex d-code justify-content-center align-items-center">
							<b-button @click="openEditModal(data.item)" variant="warning" class="btn-icon ml-1 btn-sm">
								<feather-icon icon="EditIcon" />
							</b-button>

							<b-button
								@click="deleteProvider(data.item.id, data.item.company_name)"
								variant="danger"
								class="btn-icon ml-1 btn-sm"
							>
								<feather-icon icon="Trash2Icon" />
							</b-button>
						</div>
					</template>
				</b-table>
			</div>
		</b-card>

		<ProviderModal
			v-if="showModalProvider"
			@close="showModalProvider = false"
			@registered="reset(), (showModalProvider = false)"
			:info="form"
		/>
	</div>
</template>
<script>
import Fields from "@/views/brain/administrative/views/providers/data/fields-data.js"
import ProviderModal from "@/views/brain/administrative/views/providers/components/ProviderModal.vue"
import ServiceProviders from "@/views/brain/administrative/views/providers/services/providers.service.ts"
import ViewImage from "@/components/commons/ViewImage.vue"

export default {
	components: {
		ProviderModal,
		ViewImage,
	},
	data() {
		return {
			start_page: 0,
			end_page: 0,
			total_data: 0,
			perpage: 100,
			next_page: "",
			current_page: 1,
			to_page: 0,
			perPageOptions: [10, 25, 50, 100],
			fields: Fields,
			search_input: "",
			showModalProvider: false,
			form: {
				provider_id: null,
				company_name: null,
				ruc_number: null,
				country: null,
				city: null,
				address: null,
				website: null,
				logotype: null,
				logotype_thumb: null,
				provider_contacts: null,
			},
		}
	},
	methods: {
		// openModalProvider() {
		// 	this.showModalProvider = !this.showModalProvider
		// },
		createProvider() {
			this.form.provider_id = null
			this.form.company_name = null
			this.form.ruc_number = null
			this.form.country = null
			this.form.city = null
			this.form.address = null
			this.form.website = null
			this.form.logotype = null
			this.form.logotype_thumb = null
			this.form.provider_contacts = null
			this.showModalProvider = true
		},
		async deleteProvider(id, name) {
			const confirm = await this.showConfirmSwal({
				text: `Eliminar el proveedor ${name} `,
			})
			if (!confirm.isConfirmed) return
			this.isPreloading()
			try {
				const deleted = await ServiceProviders.deleteProvider(id)
				this.showSuccessToast("Proveedor eliminado correctamente")
				this.reset()
			} catch (error) {
				// this.showErrorSwal(error.response?.data)
				console.log(error)
			} finally {
				this.isPreloading(false)
			}
		},

		async myProvider(ctx) {
			this.isPreloading()
			const params = {
				search: this.search_input,
				page: ctx.currentPage,
				perpage: ctx.perPage,
			}
			try {
				const { data } = await ServiceProviders.getProviders(params)
				this.start_page = data.from
				this.to_page = data.to
				this.total_data = data.total
				this.current_page = data.current_page
				return data.data
			} catch (error) {
				console.log(error)
				return []
			} finally {
				this.isPreloading(false)
			}
		},

		refreshTable() {
			this.$refs["table-providers"].refresh()
		},

		reset() {
			this.$refs["table-providers"].refresh()
		},

		openModal() {
			this.showModalProvider = true
		},
		openEditModal(data) {
			this.form.provider_id = data.id
			this.form.company_name = data.company_name
			this.form.ruc_number = data.ruc_number
			this.form.country = data.country
			this.form.city = data.city
			this.form.address = data.address
			this.form.website = data.website
			this.form.logotype = data.logotype
			this.form.logotype_thumb = data.logotype_thumb
			this.form.provider_contacts = data.provider_contacts
			this.showModalProvider = true
		},
	},
}
</script>

<style scoped>
.image {
	width: 50px;
	height: 50px;
	border-radius: 50%;
	overflow: hidden;
	display: flex;
	justify-content: center;
	align-items: center;
	margin: 0 auto;
}
</style>
